import 'firebase/firestore';

import firebase from 'firebase/app';

const config = {
  apiKey: 'AIzaSyBWKfIzW5g0-Yh2wMnVny1ww3aHzRTgW80',
  authDomain: 'fcis8th.firebaseapp.com',
  projectId: 'fcis8th',
  storageBucket: 'fcis8th.appspot.com',
  messagingSenderId: '874902836956',
  appId: '1:874902836956:web:ba0b9800a577252f3a8619',
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
