import './components/styles/styles.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AdBlockDetectedWrapper } from 'adblock-detect-react';
import CoursePage from './components/CoursePage';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import NoMatch from './components/NoMatch';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-EKWFGLGKVR';
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <AdBlockDetectedWrapper>
          <div className='detected'>
            Please consider disabling Ad-Blocker to help us maintain this
            project
          </div>
        </AdBlockDetectedWrapper>

        <Switch>
          <Route path='/' exact component={Home} />
          <Route
            path='/LP'
            exact
            component={() => <CoursePage title='Logic Programming' abb='LP' />}
          />
          <Route
            path='/IP'
            exact
            component={() => <CoursePage title='Image Processing' abb='IP' />}
          />
          <Route
            path='/NNDL'
            exact
            component={() => (
              <CoursePage title='Neural Networks & Deep Learning' abb='NNDL' />
            )}
          />
          <Route
            path='/DSP'
            exact
            component={() => (
              <CoursePage title='Digital Signal Processing' abb='DSP' />
            )}
          />
          <Route
            path='/IOT'
            exact
            component={() => (
              <CoursePage title='Internet Of Things' abb='IOT' />
            )}
          />
          <Route
            path='/CV'
            exact
            component={() => <CoursePage title='Computer Vision' abb='CV' />}
          />
          <Route
            path='/CR'
            exact
            component={() => (
              <CoursePage title='Computational Robotics' abb='CR' />
            )}
          />
          <Route
            path='/MS'
            exact
            component={() => (
              <CoursePage title='Modelling & Simulation' abb='MS' />
            )}
          />
          <Route
            path='/CS'
            exact
            component={() => <CoursePage title='Cyber Security' abb='CS' />}
          />
          <Route
            path='/IV'
            exact
            component={() => (
              <CoursePage title='Information Visualization' abb='IV' />
            )}
          />
          <Route
            path='/SWDP'
            exact
            component={() => (
              <CoursePage title='SW Design Patterns' abb='SWDP' />
            )}
          />
          <Route
            path='/DS'
            exact
            component={() => <CoursePage title='Data Science' abb='DS' />}
          />
          <Route
            path='/MC'
            exact
            component={() => <CoursePage title='Mobile Computing' abb='MC' />}
          />
          <Route
            path='/RTS'
            exact
            component={() => <CoursePage title='Real-time Systems' abb='RTS' />}
          />
          <Route
            path='/PAD'
            exact
            component={() => (
              <CoursePage
                title='Parallel architecture and distribution'
                abb='PAD'
              />
            )}
          />
          <Route path='*' component={NoMatch} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
