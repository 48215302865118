import './styles/NoMatch.css';

import { useLocation } from 'react-router';

const NoMatch = () => {
  const { pathname } = useLocation();
  return (
    <div className='content' id='not_found'>
      <h1>Page not found</h1>
      <p>
        The path <code>{pathname}</code> doesn't exist.
      </p>
      <p>
        Go back to the <a href='https://7th.fcis2023.me/'>home page</a> or try a
        different path.
      </p>
    </div>
  );
};

export default NoMatch;
