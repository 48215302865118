import { useEffect, useState } from 'react';

import CourseLables from './CourseLables';
import CourseTitle from './CourseTitle';
import { FilterContext } from '../FilterContext';

const Courses = () => {
  const initial = window.localStorage.getItem('filter');
  const [filter, setFilter] = useState(initial || 'all');

  useEffect(() => {
    window.localStorage.setItem('filter', filter);
  }, [filter]);

  return (
    <FilterContext.Provider value={{ filter, setFilter }}>
      <CourseLables />

      {(filter === 'all' ||
        filter === 'SC' ||
        filter === 'CS' ||
        filter === 'CSys') && (
        <CourseTitle name='Internet Of Things' to='IOT' cs sc sys />
      )}
      {(filter === 'all' || filter === 'SC' || filter === 'CS') && (
        <CourseTitle
          name='Neural Networks & Deep Learning'
          to='NNDL'
          wrp
          cs
          sc
        />
      )}
      {(filter === 'all' || filter === 'SC' || filter === 'CSys') && (
        <CourseTitle name='Computer Vision' to='CV' sc sys />
      )}
      {(filter === 'all' || filter === 'SC' || filter === 'CSys') && (
        <CourseTitle name='Modelling & Simulation' to='MS' sc sys />
      )}
      {(filter === 'all' || filter === 'CSys' || filter === 'IS') && (
        <CourseTitle name='Mobile Computing' to='MC' is sys />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Logic Programming' to='LP' cs />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Image Processing' to='IP' cs />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Digital Signal Processing' to='DSP' wrp cs />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Computational Robotics' to='CR' sc />
      )}
      {(filter === 'all' || filter === 'CSys') && (
        <CourseTitle name='Real-time Systems' to='RTS' sys />
      )}
      {(filter === 'all' || filter === 'CSys') && (
        <CourseTitle
          name='Parallel architecture and distribution'
          to='PAD'
          sys
          wrp
        />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Cyber Security' to='CS' is />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Information Visualization' to='IV' is />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='SW Design Patterns' to='SWDP' is />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Data Science' to='DS' is />
      )}
    </FilterContext.Provider>
  );
};

export default Courses;
