import './styles/Issues.css';

const Issues = () => {
  return (
    <h2 className='issues'>
      For bugs/issues/concerns you can contact me at
      <br />
      <a href='mailto:issues@fcis2023.me'>issues@fcis2023.me</a>
    </h2>
  );
};

export default Issues;
