import './styles/CourseTitle.css';

import { Link } from 'react-router-dom';

const CourseTitle = (props) => {
  const { name, to = 'OS', cs, sc, is, sys, wrp } = props;
  return (
    <Link to={`/${to}`} style={{ textDecoration: 'none' }}>
      <div className={`course_title ` + (wrp && 'warp')}>
        {name}
        {cs && <span className='cs'>&nbsp;</span>}
        {sc && <span className='sc'>&nbsp;</span>}
        {is && <span className='is'>&nbsp;</span>}
        {sys && <span className='sys'>&nbsp;</span>}
      </div>
    </Link>
  );
};

export default CourseTitle;
